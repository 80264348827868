import { useEffect, useState } from 'react'
import { useUserBarrilitoContext } from '../context/UsersBarrilitoContext'
import { getUsersBarrilito } from '../services/usersBarrilito'
import { getUsers } from '../services/users'

const useFetchUsersBarrilito = () => {

    const { usersBarrilito, setUsersBarrilito} = useUserBarrilitoContext()
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        if(usersBarrilito.length) return

        const fetchUsersBarrilito = async () => {

            const usersBarrilitoDB = await getUsers()
            setUsersBarrilito(usersBarrilitoDB)
            setIsLoading(false)

            console.log("usuarios barrilito", usersBarrilitoDB)
        }

        fetchUsersBarrilito()
        
    }, [])


  return{
    isLoading
  } 
}

export default useFetchUsersBarrilito