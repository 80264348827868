import useUsersBarrilitoTable from './useUsersBarrilitoTable'
import CustomTable from '../Custom/Table'
import useExcelExport from './useExcelExport';
import UIButton from '../UI/UIButton';

const UsersBarrilitoTable = () => {

    const { columns } = useUsersBarrilitoTable();
    const {  usersBarrilito } = useExcelExport()
    
    return <CustomTable rows={usersBarrilito} columns={columns} />

};

export default UsersBarrilitoTable;
